// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abdulbaha-js": () => import("./../../../src/pages/abdulbaha.js" /* webpackChunkName: "component---src-pages-abdulbaha-js" */),
  "component---src-pages-bahaullah-js": () => import("./../../../src/pages/bahaullah.js" /* webpackChunkName: "component---src-pages-bahaullah-js" */),
  "component---src-pages-bicentenary-js": () => import("./../../../src/pages/bicentenary.js" /* webpackChunkName: "component---src-pages-bicentenary-js" */),
  "component---src-pages-centenary-js": () => import("./../../../src/pages/centenary.js" /* webpackChunkName: "component---src-pages-centenary-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-templates-activity-template-js": () => import("./../../../src/templates/activity-template.js" /* webpackChunkName: "component---src-templates-activity-template-js" */)
}

